.accent {
  background-color: rgb(0, 126, 192); 
}

.accent:hover {
  background-color: rgb(0, 50, 80);
}

.accent:focus {
  background-color: rgb(0, 50, 80);
}

code {
  background-color: white;
  padding: 2px;
  margin: 1px;
  border-radius: 4px;
}

a {
  text-decoration: underline;
  font-weight: 600;
}